<template>
  <div>  
    <CRow>
      <CCol lg="12">
        <CButton color="info" size="sm" @click="link('home')" style="float: right;">Inicio</CButton>
        <h2>UNIVERSO</h2>
      </CCol>      
    </CRow> 
    <CRow >
      <CCol :md="6">
        <CCard>
          <CRow class="g-0">
            <CCol :md="2" style="padding: 5px 0px 5px 20px" v-if="customer.logo">
              <div style="min-height: 200px; max-height: 200px;">
                <img :src="customer.logo" style="height: 200px;" />
              </div>
            </CCol>
            <CCol :md="customer.logo ? 10 : 12">
              <CCardBody style="min-height: 213px; max-height: 220px;">
                <CCardTitle style="text-align:center; float: right;"><strong>{{customer.name}}</strong></CCardTitle>
                <CRow>
                  <CCol :md="12"><br />
                    <strong>Razón Social:</strong>
                    {{customer.razon_social ? customer.razon_social : 'N / D'}}<br /><br />
                  </CCol>                 
                  <CCol :md="12">
                    <strong>¿Que busca?</strong>
                  </CCol>
                  <CCol :md="12">
                    {{customer.objetives ? customer.objetives :  'N / D'}}
                  </CCol>
                  <CCol :md="12">
                    <br />
                    <strong>Grupo WhatsApp: </strong> 
                    <a v-if="customer.contact" :href="customer.contact">{{customer.contact}}</a>
                    <span v-else>N / D</span>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCol>
          </CRow>
        </CCard>   
      </CCol>
      <CCol :md="6">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-grid"/> Concentrado
              </slot>
            </CCardHeader>
            <CCardBody style="height: 165px;">
              <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics"
                  :fields="metrics_fields"
                  :items-per-page="1"
                  class="table-customers"
                >
                  <template #visit_total="{item}"><td class="w150 text-center">{{ item.total }}</td></template>
                  <template #visit_done="{item}"><td class="w150 text-center">{{ item.done }}</td></template>
                  <template #visit_pending="{item}"><td class="w150 text-center">{{ item.total - item.done }}</td></template>
                  <template #porcentaje="{item}"><td class="w150 text-center">{{ item.rate }}%</td></template> 
              </CDataTable>
            </CCardBody>
          </CCard>
      </CCol>
    </CRow>   
    <br>
    <CRow>
      <CCol :md="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Visitas
            </slot>
            <CButton style="float:right;" color="success" size="sm" @click="downloadExcel('first-week')">Descargar Excel</CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="visits"
              :fields="fields"
              :items-per-page="25"
              sorter
              pagination
              class="table-universo"
            >
              <template #cadena="{item}"><td class="w200 text-center">{{ item.store_name }}</td></template>
              <template #sucursal="{item}"><td class="w300 text-center">{{ item.branch_name }}</td></template>
              <template #estado="{item}"><td class="w200 text-center">{{ item.branch_state }}</td></template>                    
              <template #dia="{item}"><td class="w150 text-center">{{ item.week_day  }}</td></template>
              <template #fecha="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>
              <template #situacion="{item}"><td class="text-center">{{ item.validation_comments  }}</td></template>
              <template #status="{item}"><td class="w150 text-center">{{ (item.status == 0) ? "Pendiente": "Completada"}}</td></template>
              <template #actions="{item}">
                <td style="padding: 0px; border: 6px !important!" class="w100 text-center">
                  <CButton color="info" size="sm" @click="viewVisit(item)">Ver</CButton>                
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>        
      </CCol>      
    </CRow>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/kam';
import moment from 'moment';



export default {
  name: 'KamUniverso',
  components: {
  },
  data () {
    return {      
      page: 'kamUniverso',
      customer : {},
      visits : [],
      metrics : [],
      id: 0,
      hover: true,
      striped: false,
      border: true,
      small: false,
      fixed: false,
      dark: true,
      fields: [
        {key: 'cadena', label: 'Cadena'},
        {key: 'sucursal', label: 'Sucursal'},
        {key: 'estado', label: 'Estado'},        
        {key: 'dia', label: 'Dia Visita'},
        {key: 'fecha', label: 'Fecha'},
        {key: 'situacion', label: 'Comentario Revisión'},
        {key: 'status', label: 'Estatus'},
        {key: 'actions', label: 'Acciones'},
      ],
      metrics_fields: [
        {key: 'visit_total', label: 'Total de Visitas'},
        {key: 'visit_done', label: 'Visitas Completadas'},
        {key: 'visit_pending', label: 'Visitas Pendientes'},
        {key: 'porcentaje', label: '% Realizado'},
      ],      
    }
  },
  computed: {
  },
  mounted: async function() {
    this.loading();
    this.id = await this.$route.params.id;
      let response_customer = await ws.showCustomer(this.id);
      if(response_customer.type == "success"){
        this.customer = response_customer.data;
      }

      let date = JSON.parse(localStorage.getItem("period_date"));
      let filters = {"date" : date};

      let response_visits = await ws.customerVisits(this.id, filters);
      if(response_visits.type == "success"){
        this.visits = response_visits.data;
      }

      let response_metrics = await ws.customerVisitsMetrics(this.id, filters);
      if(response_metrics.type == "success"){
        this.metrics = [];
        this.metrics.push(response_metrics.data);
      }
    this.loaded();
  },
  methods: {     
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    viewVisit(item){        
      localStorage.setItem("after_page", "universe");  
      this.$router.push('/visita/'+item.id);
    },
    async downloadExcel(){      
      this.loading();
        let filters = {
          date: JSON.parse(localStorage.getItem("period_date")),
        };

        await ws.downloadExcelCustomerVisits(this.id,filters);
      this.loaded();
    },
    link(type, item){
      switch (type) {
        case "home":
            this.$router.push('/welcome');
          break;
      
        default:
          break;
      }      
    },    
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.table-universo table {
  font-size: 12px !important;
}
.table-universo table td{
  padding: 5px;
}
</style>
